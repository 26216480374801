<template>
        <v-map :zoom=12  :center="initialLocation" :options="{scrollWheelZoom: false}" style='width:100%;  height: 450px;'>
            <v-tilelayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"></v-tilelayer>
            <v-marker-cluster :options="clusterOptions" @clusterclick="click()" @ready="ready">
              <v-marker v-for="l in this.companyStores" :key="l.id" :lat-lng="l.latlng" :icon="icon">
                  <v-popup :content="l.text">
                  </v-popup>
            </v-marker>
            </v-marker-cluster>
        </v-map>
</template>

<script>
  import * as Vue2Leaflet from 'vue2-leaflet'
  import { latLng, Icon, icon } from 'leaflet'
  import Vue2LeafletMarkercluster from '@/components/contact/MapCluster'
  import iconUrl from '@/assets/images/logo/logo_map.png'
  import shadowUrl from '@/assets/images/logo/shadow.png'
  import { mapMutations, mapActions, mapGetters } from "vuex";

  function rand(n) {
    let max = n + 0.1
    let min = n - 0.1
    return Math.random() * (max - min) + min;
  }

  export default {
    data () {
      let customicon = icon(Object.assign({},
        Icon.Default.prototype.options,
        {iconUrl, shadowUrl}
      ))
      return {
        icon: customicon,
        clusterOptions: {},
        initialLocation: latLng(37.9983494, 23.7659929)
      }
    },
    components: {
      'v-map': Vue2Leaflet.LMap,
      'v-tilelayer': Vue2Leaflet.LTileLayer,
      'v-marker': Vue2Leaflet.LMarker,
      'v-popup': Vue2Leaflet.LPopup,
      'v-marker-cluster': Vue2LeafletMarkercluster,
    },
    methods: {
      // ...mapActions(["getCompanyStores"]),
      click: (e) => console.log("clusterclick", e),
      ready: (e) => console.log('ready', e),
    },
    async  created () {
      // this.getCompanyStores();
      this.companyStores = { 
          0: 
          {
            id: 0, 
            latlng: latLng(37.9370666, 23.6648009), 
            text: 'Ελευθερίου Βενιζέλου 6 18547 Ν. Φάληρο, Αθήνα ' + '<br>' + '+30 210 48 09 409'
          },
          1: 
          {
            id: 1, 
            latlng: latLng(38.0281945, 23.7874432), 
            text: 'Ερ. Σταυρού 4 & Κηφισίας 151 23 Μαρούσι, Αθήνα ' + '<br>' + '+30 210 68 67 000'
          },
          2: 
          {
            id: 2, 
            latlng: latLng(37.949809, 23.6638977), 
            text: 'Εθν. Μακαρίου 9 & Ελ. Βενιζέλου 1 185 47 Ν. Φάληρο, Αθήνα ' + '<br>' + '+30 210 48 09 000'
          },
          3: 
          {
            id: 3, 
            latlng: latLng(38.0292621, 23.7868783), 
            text: 'Ερ. Σταυρού 6 151 23, Μαρούσι, Αθήνα' + '<br>' + '+30 210 68 69 000'
          },
          4: 
          {
            id: 4, 
            latlng: latLng(38.0062734, 23.7963093), 
            text: 'Λεωφ. Μεσογείων 264  155 62 Χολαργός, Αθήνα' + '<br>' + '+30 210 65 02 000'
          },
          5: 
          {
            id: 5, 
            latlng: latLng(37.998991, 23.7678501), 
            text: 'Μουσών 7 - 13 115 24 Aθήνα ' + '<br>' + '+30 210 69 02 000'
          },
          6: 
          {
            id: 6, 
            latlng: latLng(35.3351638, 25.1184458), 
            text: 'Μίνωος 63 71304 Ηράκλειο Κρήτης ' + '<br>' + '+30 2810 37 38 00'
          },
          7: 
          {
            id: 7, 
            latlng: latLng(38.056174, 23.8443357), 
            text: 'Κουντουριώτου 14 15127 Μελίσσια ' + '<br>' + '+30 210 63 97 144'
          },
          8: 
          {
            id: 8, 
            latlng: latLng(38.0737719, 23.8127123), 
            text: 'Λεβίδου 16 (έδρα) 14 564, Κηφισιά, Αθήνα ' + '<br>' + '+30 212 80 86 100'
          },
          9: 
          {
            id: 9, 
            latlng: latLng(37.9983494, 23.7659929), 
            text: 'Αναστασίου Γεωργίου 11 115 24 Αθήνα ' + '<br>' + '+30 210 69 02 082'
          },
          10: 
          {
            id: 10, 
            latlng: latLng(38.0443608, 23.8097376), 
            text: 'Σωρού 35 151 25 Μαρούσι ' + '<br>' + '+30 210 61 09 992'
          },
          // 11:
          // {
          //   id: 11, 
          //   latlng: latLng(38.0245312, 24.0082548), 
          //   text: 'Λιμάνι Ραφήνας' + '<br>' + '190 09 Ραφήνα'
          // }
        }
    },
    // computed: {
    //    ...mapGetters (["companyStores"]),
    // },
    mounted() {
      setTimeout(() => {
        this.$nextTick(() =>{
          this.clusterOptions = { disableClusteringAtZoom: 11 }
        });
      }, 5000);
    }
  }
</script>

<style>
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, 
.leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path 
{
    width: 35px !important;
    height: 35px !important;
}
.leaflet-control-container { position: absolute; right: 56px } 

  
</style>