<template>
  <div>
    <gmap-map
      :center="center"
      :zoom="11"
      style="width: 100%; height: 450px"
      :options="mapOptions"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="toggleInfoWindow(m, index)"
        :icon="{ url: require('@/assets/images/logo/logo_map.png') }"
        :option="MarkerOptions"
      />
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>
<script>
export default {
  data() {
    return {
      center: { lat: 37.9983494, lng: 23.7659929 },
      mapOptions: {
        fullscreenControl: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        disableDefaultUi: false,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#444444",
              },
            ],
          },
          {
            featureType: "administrative.locality",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f2f2f2",
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                visibility: "simplified",
              },
              {
                saturation: "-65",
              },
              {
                lightness: "45",
              },
              {
                gamma: "1.78",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#e3b3af",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                saturation: "-33",
              },
              {
                lightness: "22",
              },
              {
                gamma: "2.08",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "labels.icon",
            stylers: [
              {
                color: "#000000",
              },
            ],
          },
          {
            featureType: "transit.station.airport",
            elementType: "geometry",
            stylers: [
              {
                gamma: "2.08",
              },
              {
                hue: "#ffa200",
              },
            ],
          },
          {
            featureType: "transit.station.airport",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "simplified",
              },
              {
                saturation: "-55",
              },
              {
                lightness: "-2",
              },
              {
                gamma: "1.88",
              },
              {
                hue: "#ffab00",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#bbd9e5",
              },
              {
                visibility: "simplified",
              },
            ],
          },
        ],
      },
      map: null,
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      fullscreenControl: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      MarkerOptions: {
        zIndex: 999999,
        opacity: 0.2,
      },

      markers: [
        {
          title: "Metropolitan Hospital",
          address:
            "Εθνάρχου Μακαρίου 9 & Ελ. Βενιζέλου 1, 18547 Ν. Φάληρο, Αθήνα",
          phone: "210 48 09 000",
          email: "information@metropolitan-hospital.gr",
          link: "https://www.metropolitan-hospital.gr",
          // position: "37.949809,23.6638977",
          position: {lat:37.949809, lng:23.6638977},
          mapLink:
            "https://www.google.com/maps/search/%CE%95%CE%B8%CE%BD%CE%AC%CF%81%CF%87%CE%BF%CF%85+%CE%9C%CE%B1%CE%BA%CE%B1%CF%81%CE%AF%CE%BF%CF%85+9+%26+%CE%95%CE%BB.+%CE%92%CE%B5%CE%BD%CE%B9%CE%B6%CE%AD%CE%BB%CE%BF%CF%85+1,+18547+%CE%9D.+%CE%A6%CE%AC%CE%BB%CE%B7%CF%81%CE%BF,+%CE%91%CE%B8%CE%AE%CE%BD%CE%B1/@37.9693536,23.7033841,13z/data=!3m1!4b1?hl=el-GR",
        },
        {
          title: "HealthSpot - Πειραιά",
          address: "Ακτή Μιαούλη, 185 38 Πειραιάς",
          phone: "212 80 86 400",
          email: "info@healthspot.hhg.gr",
          link: "https://www.healthspot.hhg.gr",
          // position: "37.937296,23.635021",
          position: {lat:37.937296, lng:23.635021},
          mapLink:
            "https://www.google.com/maps/place/%CE%91%CE%BA%CF%84%CE%AE+%CE%9C%CE%B9%CE%B1%CE%BF%CF%8D%CE%BB%CE%B7,+%CE%A0%CE%B5%CE%B9%CF%81%CE%B1%CE%B9%CE%AC%CF%82+185+38,+%CE%95%CE%BB%CE%BB%CE%AC%CE%B4%CE%B1/@37.9373001,23.632827,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1bbdb731333b7:0x832a96a02a2b0943!8m2!3d37.9372959!4d23.635021?hl=el-GR",
        },
        {
          title: "HealthSpot - Γλυφάδας",
          address: "Γρηγορίου Λαμπράκη 62, 166 74 Γλυφάδα",
          phone: "212 80 86 300",
          email: "info@healthspot.hhg.gr",
          link: "https://www.healthspot.hhg.gr",
          // position: "37.869223,23.758492",
          position: {lat:37.869223, lng:23.758492},
          mapLink:
            "https://www.google.com/maps/place/HealthSpot+%CE%93%CE%BB%CF%85%CF%86%CE%AC%CE%B4%CE%B1%CF%82/@37.8692155,23.7563566,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1bffa210b1ce3:0x97e247af17604bf2!8m2!3d37.8692232!4d23.7584918?hl=el-GR",
        },
        {
          title: "HealthSpot - Περιστερίου",
          address: "Παναγή Τσαλδάρη & Σαρανταπόρου 1, 121 34 Περιστέρι",
          phone: "212 80 86 200",
          email: "info@healthspot.hhg.gr",
          link: "https://www.healthspot.hhg.gr",
          // position: "38.014805,23.693203",
          position: {lat:38.014805, lng:23.693203},
          mapLink:
            "https://www.google.com/maps/place/HealthSpot+%CE%A0%CE%B5%CF%81%CE%B9%CF%83%CF%84%CE%B5%CF%81%CE%AF%CE%BF%CF%85/@38.0148095,23.6910094,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1a3949fc6cc65:0x6e6d15aa1314fbca!8m2!3d38.014822!4d23.6931619?hl=el-GR",
        },
        // {
        //   title: "HealthSpot - Ραφήνας",
        //   address: "Λιμάνι Ραφήνας, 190 09 Ραφήνα",
        //   phone: "212 80 86 500",
        //   email: "info@healthspot.hhg.gr",
        //   link: "https://www.healthspot.hhg.gr",
        //   // position: "38.0207868,24.008844",
        //   position: {lat:38.0207868, lng:24.008844},
        //   mapLink:
        //     "https://www.google.com/maps/place/%CE%9B%CE%B9%CE%BC%CE%AC%CE%BD%CE%B9+%CE%A1%CE%B1%CF%86%CE%AE%CE%BD%CE%B1%CF%82/@38.0245312,24.0082548,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1839ae144a783:0x5668d7f482e68b0a!8m2!3d38.024527!4d24.0104488?hl=el-GR",
        // },
        {
          title: "Λητώ",
          address: "Μουσών 7 - 13, 11524 Aθήνα",
          phone: "210 69 02 000",
          email: "info@leto.gr",
          link: "https://www.leto.gr",
          // position: "37.9983509,23.7660158",
          position: {lat:37.9986037, lng:23.7681193},
          mapLink:
            "https://www.google.com/maps/place/%CE%9B%CE%B7%CF%84%CF%8E/@37.9989064,23.765809,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1980940bf6a37:0x82363dd8ab1a74bb!8m2!3d37.9989022!4d23.768003?hl=el-GR",
        },
        {
          title: "A-Lab Αθήνα",
          address: "Αναστασίου Γεωργίου 11, 115 24 Αθήνα",
          phone: "210 69 02 082",
          email: "alab@leto.gr",
          link: "https://alab.gr/",
          // position: "37.9983509,23.7660158",
          position: {lat:37.9983494, lng:23.7659929},
          mapLink:
            "https://www.google.com/maps/place/%CE%91%CE%BD%CE%B1%CF%83%CF%84%CE%B1%CF%83%CE%AF%CE%BF%CF%85+%CE%93%CE%B5%CF%89%CF%81%CE%B3%CE%AF%CE%BF%CF%85+11,+%CE%91%CE%B8%CE%AE%CE%BD%CE%B1+115+24/@37.9983494,23.7659929,17z/data=!3m1!4b1!4m5!3m4!1s0x14a198093b6ce18d:0xd3d7433a3af3ceab!8m2!3d37.9983494!4d23.7681869?hl=el-GR",
        },
        {
          title: "Metropolitan General",
          address: "Λεωφόρος Μεσογείων 264, 15562 Χολαργός, Αθήνα",
          phone: "210 65 02 000",
          email: "infogeneral@metropolitan-hospital.gr",
          link: "https://www.metropolitan-general.gr",
          // position: "38.0062734,23.7963093",
          position: {lat:38.0062734, lng:23.7963093},
          mapLink:
            "https://www.google.com/maps/place/METROPOLITAN+GENERAL/@37.9989064,23.765809,17z/data=!4m5!3m4!1s0x14a19847c16711df:0x2dcd2026fc36e5f4!8m2!3d38.0062734!4d23.798498?hl=el-GR",
        },
        {
          title: "HealthSpot - Κηφισιάς",
          address: "Λεβίδου 16, 145 64 Κηφισιά",
          phone: "212 80 86 100",
          email: "info@healthspot.hhg.gr",
          link: "https://www.healthspot.hhg.gr",
          // position: "38.073772,23.814901",
          position: {lat:38.073772, lng:23.814901},
          mapLink:
            "https://www.google.com/maps/place/HealthSpot+%CE%9A%CE%B7%CF%86%CE%B9%CF%83%CE%B9%CE%AC%CF%82/@38.0738188,23.8127236,17z/data=!3m1!4b1!4m5!3m4!1s0x14a19f1d653e4b4f:0x5834c85e879d5bb3!8m2!3d38.0738146!4d23.8149176?hl=el-GR",
        },
        {
          title: "Υγεία",
          address: "Ερυθρού Σταυρού 4 & Κηφισίας, 151 23 Μαρούσι, Αθήνα",
          phone: "210 68 67 000",
          email: "info@hygeia.gr",
          link: "https://www.hygeia.gr",
          // position: "38.0271513,23.7873774",
          position: {lat:38.0271513, lng:23.7873774},
          mapLink:
            "https://www.google.com/maps/place/%CE%A5%CE%B3%CE%B5%CE%AF%CE%B1/@38.0281987,23.7874379,17z/data=!3m1!4b1!4m5!3m4!1s0x14a19893aaaaaaab:0x2f2f425014183c8f!8m2!3d38.0281945!4d23.7896319?hl=el-GR",
        },
        {
          title: "Μητέρα",
          address: "Ερυθρού Σταυρού 6, 15123, Μαρούσι, Αθήνα",
          phone: "210 68 69 000",
          email: "info@mitera.gr",
          link: "https://www.mitera.gr",
          // position: "38.0271513,23.7873774",
          position: {lat:38.0271513, lng:23.7873774},
          mapLink:
            "https://www.google.com/maps/place/%CE%9C%CE%B7%CF%84%CE%AD%CF%81%CE%B1/@38.0292663,23.786873,17z/data=!3m1!4b1!4m5!3m4!1s0x14a198f2ea534177:0xf4275c053ce5de62!8m2!3d38.0292621!4d23.789067?hl=el-GR",
        },
        {
          title: "CRETA INTERCLINIC",
          address: "Μίνωος 63, 71304 Ηράκλειο Κρήτης",
          phone: "2810 37 38 00",
          email: "info@cic.gr",
          link: "https://cic.gr/el/",
          // position: "35.3351638,25.1184458",
          position: {lat:35.3351638, lng:25.1184458},
          mapLink:
            "https://www.google.com/maps/place/CRETA+INTERCLINIC/@35.3351682,25.1184405,17z/data=!3m1!4b1!4m5!3m4!1s0x149a598af4b4c2c7:0x87f786ed66ff89b8!8m2!3d35.3351638!4d25.1206345?hl=el-GR",
        },
        {
          title: "BUSINESS CARE",
          address: "Κουντουριώτου 14, Μελίσσια",
          phone: "210 63 97 144",
          email: "info@businesscare.gr",
          link: "https://www.businesscare.gr/",
          // position: "38.056174,23.8443357",
          position: {lat:38.056174, lng:23.8443357},
          mapLink:
            "https://www.google.com/maps/place/%CE%9A%CE%BF%CF%85%CE%BD%CF%84%CE%BF%CF%85%CF%81%CE%B9%CF%8E%CF%84%CE%BF%CF%85+14,+%CE%9C%CE%B5%CE%BB%CE%AF%CF%83%CF%83%CE%B9%CE%B1+151+27/data=!4m2!3m1!1s0x14a19faeb5d4706b:0xd8b3baa02e0b26c?sa=X&ved=2ahUKEwiRp8DJiavnAhUGLVAKHdEWAQwQ8gEwAHoECAsQAQ",
        },
      ],

      // markers: [
      //     {
      //       name: "Ελευθερίου Βενιζέλου 6 18547 Ν. Φάληρο, Αθήνα ",
      //       location: "+30 210 48 09 409",
      //       position: {lat:37.9370666, lng:23.6648009}
      //     },
      //     {
      //       name: "Λιμάνι Ραφήνας",
      //       location: "190 09 Ραφήνα",
      //       position: {lat:38.0245312, lng:24.0082548}
      //     },
      //     {
      //       name: "Σωρού 35 151 25 Μαρούσι",
      //       location: "+30 210 61 09 992",
      //       position: {lat:38.0443608, lng:23.8097376}
      //     },
      //     {
      //       name: "Αναστασίου 11 115 24 Αθήνα",
      //       location: "+30 210 69 02 082",
      //       position: {lat:37.9985475, lng:23.7654877}
      //     },
      //     {
      //       name: "Λεβίδου 16 (έδρα) 14 564, Κηφισιά, Αθήνα",
      //       location: "+30 212 80 86 100",
      //       position: {lat:38.0737719, lng:23.8127123}
      //     },
      //     {
      //       name: "Κουντουριώτου 14 15127 Μελίσσια",
      //       location: "+30 210 63 97 144",
      //       position: {lat:38.056174, lng:23.8443357}
      //     },
      //     {
      //       name: "Μίνωος 63 71304 Ηράκλειο Κρήτης",
      //       location: "+30 2810 37 38 00",
      //       position: {lat:35.3351638, lng:25.1184458}
      //     },
      //     {
      //       name: "Μουσών 7 - 13 115 24 Aθήνα",
      //       location: "+30 210 69 02 000",
      //       position: {lat:37.998991, lng:23.7678501}
      //     },
      //     {
      //       name: "Λεωφ. Μεσογείων 264  155 62 Χολαργός, Αθήνα",
      //       location: "+30 210 65 02 000",
      //       position: {lat:38.0062734, lng:23.7963093}
      //     },
      //     {
      //       name: "Ερ. Σταυρού 6 151 23, Μαρούσι, Αθήνα",
      //       location: "+30 210 68 69 000",
      //       position: {lat:38.0292621, lng:23.7868783}
      //     },
      //     {
      //       name: "Εθν. Μακαρίου 9 & Ελ. Βενιζέλου 1 185 47 Ν. Φάληρο, Αθήνα",
      //       location: "+30 210 48 09 000",
      //       position: {lat:37.949809, lng:23.6638977}
      //     },
      //     {
      //       name: "Ερ. Σταυρού 4 & Κηφισίας 151 23 Μαρούσι, Αθήνα",
      //       location: "+30 210 68 67 000",
      //       position: {lat:38.0281945, lng:23.7874432}
      //     },
      //   ]
    };
  },

  methods: {
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent: function(marker) {
      // return (
      // `<div class="">
      //   <div>
      //     <div>
      //       <div class="m-2"><span style="font-weight: bold;">Device Name: </span>
      //         ${marker.name}
      //       </div>
      //     </div>
      //     <div class="m-2"><span style="font-weight: bold;">Location:  </span>
      //       ${marker.location}
      //       <br>
      //     </div>
      //   </div>
      // </div>`);
      return `<div class="contact-details">
            <h3>${marker.title}</h3>
            <i class="fas fa-hospital" style="color: #e4042d;
                margin-right: 7px;
                margin-bottom: 10px;
                float: left;
                margin-top: 3px;" aria-hidden="true"></i> <p style='margin-left: 25px'>
            <a style="color: black;" href="${marker.mapLink}" 
            target="_blank">${marker.address}</a></p>
            <i class="fas fa-phone-volume" aria-hidden="true" style="color: #e4042d;
                margin-right: 7px;
                margin-bottom: 10px;
                float: left;
                margin-top: 3px;" aria-hidden="true"></i>  <p style='margin-left: 25px'> <a style="color: black;" href="tel:+302106502000" target="_blank">${marker.phone}</a></p>
            <i class="fas fa-envelope-open" aria-hidden="true" style="color: #e4042d;
                margin-right: 7px;
                margin-bottom: 10px;
                float: left;
                margin-top: 3px;" aria-hidden="true"></i> <p style='margin-left: 25px'> ${marker.email} </p>
            <i class="fas fa-globe" aria-hidden="true" style="color: #e4042d;
                margin-right: 7px;
                margin-bottom: 10px;
                float: left;
                margin-top: 3px;" aria-hidden="true"></i>  <p style='margin-left: 25px'><a style="color: black;" href="${marker.link}" target="_blank">${marker.link}</a> </p>
        </div>`;
    },
  },
};
</script>
